import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Testimonials = ({ testimonials }) => {
  const [activeSlide, setActiveSlide] = useState(1);
  const [clickedslide, setClickedslide] = useState(false);
  const testimonyLength = testimonials.length;

  useEffect(() => {
    // This variable prevents race condition
    let current = 1;
    const cycleReviews = () => {
      if (current === testimonyLength) {
        current = 1;
      } else {
        current += 1;
      }
      setActiveSlide(current);
    };

    // Cycle so long as indicators are not clicked
    if (!clickedslide) {
      // intervalId identified so it can be canceled on unmount
      const intervalId = setInterval(() => {
        cycleReviews();
      }, 10000);
      // Removes interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [clickedslide]);

  const handleClick = (e) => {
    setClickedslide(true);
    let items = document.querySelector(".carousel__indicator").childNodes;
    items.forEach((item, index) => {
      if (index === e.target.id - 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
    setActiveSlide(e.target.id);
  };

  return (
    <div
      className="App section"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "--sal-duration": "1s",
      }}
      data-sal="slide-right"
      data-sal-easing="easeInQuint"
    >
      <ul
        className="carousel__list"
        style={{ listStyle: "none", margin: 0, padding: 0 }}
      >
        {testimonials.map((testimonial, index) => {
          const { author, quote } = testimonial;
          const count = index + 1;
          return (
            <li
              className={`carousel__item
                ${count === activeSlide ? " active" : ""}
                ${count < activeSlide ? " left" : ""}
                ${count > activeSlide ? " right" : ""}
              `}
              key={count}
            >
              <blockquote className="carousel__quote">
                <cite>
                  <span className="carousel__name">{author}</span>
                </cite>
                <p>"{quote}"</p>
              </blockquote>
            </li>
          );
        })}
        <li className="carousel__indicator" style={{ cursor: "pointer" }}>
          {testimonials.map((value, index) => {
            return (
              <span
                key={index + 1}
                onClick={handleClick}
                id={index + 1}
                className={`carousel__dot${
                  activeSlide === index + 1 ? " active" : ""
                }`}
              />
            );
          })}
        </li>
      </ul>
    </div>
  );
};

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;
