import React from "react";
import Helmet from "react-helmet";
import Offerings from "../Offerings";
import Testimonials from "../Testimonials";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";

const waveSvg = "/img/wave.svg";

const HomePageTemplate = ({
  title,
  subtitle,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  testimonials,
}) => {
  const heroBackground = "/img/doctor-arms.jpg";
  const diversityImage =
    "/img/spectrum-insurance-group-offers-employee-benefits-for-diverse-groups.jpg";
  const homePageUrl = "https://spectruminsurancegroup.net";
  const visionImage = homePageUrl + "/img/healthplans.jpg";
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta property="og:title" content={meta_title} />
        <meta name="description" content={meta_description} />
        <meta property="og:image" content={visionImage} />
        <meta property="og:url" content={homePageUrl} />
      </Helmet>
      <section
        className="hero is-bold is-large"
        style={{ margin: "0 5em 0 0", "--sal-duration": ".6s" }}
        data-sal="slide-right"
        data-sal-easing="easeInQuint"
      >
        <div
          className="hero-body"
          style={{
            background: `linear-gradient(180deg,rgba(49,128,192,0.2) -60%, rgba(49,128,192,0.6) 100%), url(${heroBackground})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "0 0 30vw 0",
            overflow: "hidden",
            zIndex: 10,
            boxShadow: "20px 0px 0px 0px rgba(14,225,244,0.33)",
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section has-text-centered">
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    color="white"
                    size="5x"
                  />
                  <h1
                    className="has-text-white"
                    style={{
                      fontSize: "5em",
                      fontWeight: 400,
                      lineHeight: 1.125,
                    }}
                  >
                    {title}
                  </h1>
                  <h3 className="subtitle has-text-white">{subtitle}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section section--gradient"
        style={{
          background: `url(${waveSvg})`,
          backgroundPosition: "bottom",
          backgroundSize: "200% 30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div
                    className="columns section"
                    data-sal="slide-up"
                    data-sal-easing="easeInQuint"
                    style={{
                      "--sal-duration": ".6s",
                    }}
                  >
                    <div className="column">
                      {/* <FontAwesomeIcon
                        icon={faCheckSquare}
                        color="#209CEE"
                        size="4x"
                      /> */}
                      <h3
                        className="has-text-weight-semibold is-size-2"
                        style={{ marginTop: ".75em" }}
                      >
                        {heading}
                      </h3>
                      <p>{description}</p>
                    </div>
                    <div
                      className="column"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={diversityImage}
                        alt="spectrum insurance group offers employee benefits for diverse groups. - Customized Employee Benefits For Virginia, Maryland, and DC"
                        style={{
                          borderRadius: "30px 5vh 0px 5vw",
                          overflow: "hidden",
                          borderColor: "#2f8cff",
                          borderLeftWidth: "2vw",
                          boxShadow:
                            "0px 30px 80px -30px rgba(102,141,186,0.7)",
                        }}
                      />
                    </div>
                  </div>
                  <Offerings gridItems={offerings.blurbs} />
                  <div class="section">
                    <div class="content has-text-centered">
                      <h2 class="h2">
                        <a
                          href="/health-plans"
                          title="Learn How To
                        Take Advantage of Lower Cost High Deductible Health Plans"
                        >
                          Click Here
                        </a>{" "}
                        To Learn How To Take Advantage of Lower Cost High
                        Deductible Health Plans
                      </h2>
                    </div>
                  </div>
                  <Testimonials testimonials={testimonials} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-medium is-primary">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div
                  className="section has-text-centered"
                  style={{ "--sal-duration": ".6s" }}
                  data-sal="slide-up"
                  data-sal-easing="easeInQuint"
                >
                  <FontAwesomeIcon
                    icon={faUniversalAccess}
                    color="white"
                    size="4x"
                  />
                  <h1
                    className="title has-text-white is-size-1"
                    style={{
                      fontSize: "5em !important",
                      fontWeight: 400,
                    }}
                  >
                    We Deliver Better Benefits
                  </h1>
                  <h3 className="subtitle has-text-white">
                    We serve the Maryland, DC, and Virginia area and we’re ready
                    to deliver better benefits to you.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
};

export default HomePageTemplate;
