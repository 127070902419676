import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Offerings = ({ gridItems }) => (
  <div
    className="columns is-multiline"
    data-sal="slide-left"
    data-sal-easing="easeInQuint"
    style={{
      "--sal-duration": ".6s",
      marginTop: "1em",
    }}
  >
    {gridItems.map((item, index) => (
      <div key={index} className="column is-4" style={{ borderRadius: "5px" }}>
        <section className="" style={{ margin: "1em 1em" }}>
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <FontAwesomeIcon icon={faCheckCircle} color="#209CEE" size="2x" />
            </div>
            <div className="column">
              <p>
                <strong>{item.title}</strong>
              </p>
              <p>{item.text}</p>
            </div>
          </div>
        </section>
      </div>
    ))}
  </div>
);

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default Offerings;
